import { XSRF_HEADER_NAME } from './constants/csrf';

/* @flow */
type Fetch = (url: string, options: ?any) => Promise<any>;

type Options = {
  baseUrl: string,
  cookie?: string,
  csrfToken?: string,
};

const jwtHeader = token => ({ Authorization: `JWT ${token}` });

function getHeaders(store) {
  // Dynamic get token from store
  if (store) {
    const { auth } = store.getState();
    return {
      ...(auth && auth.token ? jwtHeader(auth.token) : null),
    };
  }
  return null;
}

function createFetch(
  fetch: Fetch,
  { baseUrl, cookie, jwt, apiKey, store, csrfToken }: Options,
) {
  // NOTE: Tweak the default options to suite your application needs
  const defaultHeaders = {
    ...(jwt ? jwtHeader(jwt) : null),
    ...(apiKey ? { 'Api-Key': `${apiKey}` } : null),
    ...(csrfToken ? { [XSRF_HEADER_NAME]: `${csrfToken}` } : null),
  };

  const defaults = {
    method: 'POST', // handy with GraphQL backends
    mode: baseUrl ? 'cors' : 'same-origin',
    credentials: baseUrl ? 'include' : 'same-origin',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...(cookie ? { Cookie: cookie } : null),
      ...defaultHeaders,
    },
  };

  return (url: string, options: any) => {
    if (url.startsWith('/graphql') || url.startsWith('/api')) {
      const fineUrl = url.startsWith('http') ? url : `${baseUrl}${url}`;
      return fetch(fineUrl, {
        ...defaults,
        ...options,
        headers: {
          ...defaults.headers,
          ...(options && options.headers),
          ...getHeaders(store),
        },
      });
    }

    return fetch(url, {
      ...options,
      headers: {
        ...defaultHeaders,
        ...(options && options.headers),
        ...getHeaders(store),
      },
    });
  };
}

export default createFetch;
