import 'whatwg-fetch';
import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import deepForceUpdate from 'react-deep-force-update';
import queryString from 'query-string';
import Cookies from 'js-cookie';
import moment from 'moment';
import { isBrowser, isMobile, isMobileOnly } from 'react-device-detect';
import { createPath } from 'history/PathUtils';
import { addLocaleData } from 'react-intl';
import uk from 'react-intl/locale-data/uk';
import ru from 'react-intl/locale-data/ru';
import App from './components/App';
import createFetch from './createFetch';
import configureStore from './store/configureStore';
import history from './history';
import { updateMeta, updateLink } from './DOMUtils';
import router from './router';
import { hideMenu } from './actions/app/menu';
import { getIntl } from './actions/intl';
import { setDevice } from './actions/app/device';
import * as GA from './ga';

if (!__DEV__) {
  Sentry.init({
    dsn:
      'https://21bee2ef5a41421692f7d5552fd66b5c@o558612.ingest.sentry.io/5749467',
    // tracesSampleRate: 1.0,
  });
}

/* eslint-disable global-require */

addLocaleData(uk);
addLocaleData(ru);

const store = configureStore(window.App?.state || {}, { history });

GA.initialize({
  trackingId: window.App.googleTrackingId,
  adsTrackingId: window.App.googleAdsTrackingId,
});

// Universal HTTP client
const fetch = createFetch(window.fetch, {
  baseUrl: window.App.apiUrl,
  jwt: window.App.state.auth.token,
  store,
  csrfToken: Cookies.get('_xsrf_token'),
});

const intl = store.dispatch(getIntl());
moment.locale(intl.locale);

store.dispatch(
  setDevice({
    isMobile,
    isBrowser,
    isMobileOnly,
  }),
);

const context = {
  // Enables critical path CSS rendering
  // https://github.com/kriasoft/isomorphic-style-loader
  insertCss: (...styles) => {
    // eslint-disable-next-line no-underscore-dangle
    const removeCss = styles.map(x => x._insertCss());
    return () => {
      removeCss.forEach(f => f());
    };
  },
  store,
  storeSubscription: null,
  fetch,
  // intl instance as it can be get with injectIntl
  intl,
  locale: intl.locale,
};

// Switch off the native scroll restoration behavior and handle it manually
// https://developers.google.com/web/updates/2015/09/history-api-scroll-restoration
const scrollPositionsHistory = {};
if (window.history && 'scrollRestoration' in window.history) {
  window.history.scrollRestoration = 'manual';
}

let onRenderComplete = function initialRenderComplete() {
  const elem = document.getElementById('css');
  if (elem) elem.parentNode.removeChild(elem);
  onRenderComplete = function renderComplete(route, location) {
    document.title = route.title;

    updateMeta('description', route.description);
    // Update necessary tags in <head> at runtime here, ie:
    // updateMeta('keywords', route.keywords);
    // updateCustomMeta('og:url', route.canonicalUrl);
    // updateCustomMeta('og:image', route.imageUrl);
    if (route.seo && route.seo.canonical) {
      updateLink(
        'canonical',
        `${document.location.origin}${route.seo.canonical}`,
      );
    }

    let scrollX = 0;
    let scrollY = 0;
    const pos = scrollPositionsHistory[location.key];
    if (pos) {
      scrollX = pos.scrollX;
      scrollY = pos.scrollY;
    } else {
      const targetHash = location.hash.substr(1);
      if (targetHash) {
        const target = document.getElementById(targetHash);
        if (target) {
          scrollY = window.pageYOffset + target.getBoundingClientRect().top;
        }
      }
    }

    // Restore the scroll position if it was saved into the state
    // or scroll to the given #hash anchor
    // or scroll to top of the page
    window.scrollTo(scrollX, scrollY);
  };
};

const container = document.getElementById('app');
let appInstance;
let currentLocation = history.location;

// Re-render the app when window.location changes
async function onLocationChange(location, action) {
  // Remember the latest scroll position for the previous location
  scrollPositionsHistory[currentLocation.key] = {
    scrollX: window.pageXOffset,
    scrollY: window.pageYOffset,
  };
  // Delete stored scroll position for next page if any
  if (action === 'PUSH') {
    delete scrollPositionsHistory[location.key];
  }
  currentLocation = location;
  context.intl = store.dispatch(getIntl());

  const isInitialRender = !action;

  try {
    // Traverses the list of routes in the order they are defined until
    // it finds the first route that matches provided URL path string
    // and whose action method returns anything other than `undefined`.
    context.pathname = location.pathname;
    context.query = queryString.parse(location.search);
    context.locale = store.getState().intl.locale;

    const route = await router.resolve({
      ...context,
      path: location.pathname, // TODO: might be removed
      isInitialRender,
    });

    // Prevent multiple page renders during the routing process
    if (currentLocation.key !== location.key) {
      return;
    }

    if (route.redirect) {
      history.replace(route.redirect);
      return;
    }

    context.store.dispatch(hideMenu());
    GA.pageview(createPath(location));

    const renderReactApp = isInitialRender ? ReactDOM.hydrate : ReactDOM.render;
    appInstance = renderReactApp(
      <App context={context}>{route.component}</App>,
      container,
      () => onRenderComplete(route, location),
    );
  } catch (error) {
    if (__DEV__) {
      throw error;
    }

    console.error(error);

    // Do a full page reload if error occurs during client-side navigation
    if (action && currentLocation.key === location.key) {
      window.location.reload();
    }
  }
}

let isHistoryObserved = false;
export default function main() {
  // Handle client-side navigation by using HTML5 History API
  // For more information visit https://github.com/mjackson/history#readme
  currentLocation = history.location;
  if (!isHistoryObserved) {
    isHistoryObserved = true;
    history.listen(onLocationChange);
  }
  onLocationChange(currentLocation);
}

// Enable Hot Module Replacement (HMR)
if (module.hot) {
  module.hot.accept('./router', () => {
    if (appInstance) {
      // Force-update the whole tree, including components that refuse to update
      deepForceUpdate(appInstance);
    }

    onLocationChange(currentLocation);
  });
}
